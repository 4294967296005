// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //auth0CallBackUrl: 'http://localhost:8100',
  auth0CallBackUrl: window.location.origin + '/',
  auth0Domain: 'layerjot-dev.us.auth0.com',
  auth0Audience: 'https://layerjot-dev.us.auth0.com/api/v2/',
  auth0ClientID: 'I4FVeeK2MgPSsDSn94v4ZIBQynsG68m7',
  appPackageID: 'com.layerjot.hub.sid',
  apiPath: 'https://dev-api.layerjot.com/api/1.0',
  apiPathV2: 'https://dev-api.layerjot.com/api/v2',
  defaultOrgInviteCode: 'SID22',
  unsplashAccessKey: 'jYUoUzoFOm80PYojnQ9cbdu59hU3K-sqwK4ycRMEvPU',
  supportEmail: 'support+sid@layerjot.com',
  pegBoardUsers: ['@layerjot.com', 'a@b.co', 'tbd13@protonmail.com'],
  gaTrackerID: 'UA-157592946-1',
  trackAnalytics: false,
  guideInstList: ['4505'],
  checkListInst: ['NL3138'],
  cookieDomain: '.layerjot.com',
  shortEnv: 'dev',
  mediaPath: 'https://dev-media.layerjot.com',
  firebaseConfig: {
    apiKey: "AIzaSyAz7ThKW2X3RAgqYUUF5l4y9Na-IoXy9Gs",
    authDomain: "layerjot-mobile-c05e1.firebaseapp.com",
    projectId: "layerjot-mobile-c05e1",
    storageBucket: "layerjot-mobile-c05e1.firebasestorage.app",
    messagingSenderId: "718547400456",
    appId: "1:718547400456:web:6de96464e49c9e21805ebd",
    measurementId: "G-B458PMPEEG"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
